<template>
  <div class="watch-page">
    <section-panel class="watch-chart" title="血氧 2023年11月1日 周一" v-for="(item, index) in data" :key="index">
      <chart-base :series="getSeries(item)" :color="color" :x-axis="xAxis"></chart-base>
    </section-panel>
    <section-panel class="grid-span-1" title="今日数据"></section-panel>
    <section-panel class="grid-span-1" title="血氧波动范围"></section-panel>
    <section-panel class="grid-span-1" title="日平均值"></section-panel>
    <section-panel class="grid-span-1" title="本周数据"></section-panel>
    <section-panel class="grid-span-1" title="血氧波动范围"></section-panel>
    <section-panel class="grid-span-1" title="周平均值"></section-panel>
    <section-panel class="grid-span-1" title="本月数据"></section-panel>
    <section-panel class="grid-span-1" title="血氧波动范围"></section-panel>
    <section-panel class="grid-span-1" title="月平均值"></section-panel>
  </div>
</template>

<script>
import { graphic } from 'echarts';
import SectionPanel from './section-panel';
import ChartBase from './chart-base';

export default {
	components: { SectionPanel, ChartBase },
	data() {
		return {
			color: ['#f3afaf'],
			xAxis: [
				1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
				26, 27, 28, 29, 30, 31, 32,
			],
			data: [
				[
					{
						data: [
							2, 2, 4, 6, 2, 2, 3, 4, 6, 2, 4, 4, 6, 2, 3, 4, 6, 3, 4, 6, 2, 2, 4, 6, 4, 6, 2, 4, 4,
							6, 2, 3,
						],
						name: '血氧值',
					},
				],
				[
					{
						data: [
							4, 6, 2, 4, 4, 6, 2, 3, 2, 4, 4, 6, 2, 3, 4, 6, 2, 1, 2, 6, 4, 6, 2, 3, 4, 6, 2, 1, 4,
							6, 4, 6,
						],
						name: '血氧值',
					},
				],
				[
					{
						data: [
							2, 4, 6, 2, 4, 4, 6, 2, 3, 2, 8, 4, 6, 2, 4, 4, 6, 2, 5, 4, 6, 7, 4, 6, 2, 9, 4, 6, 2,
							7, 4, 6,
						],
						name: '血氧值',
					},
				],
			],
		};
	},
	methods: {
		getSeries(data) {
			if (!data) return [];
			return data.map((s) => {
				s.type = 'bar';
				s.barMaxWidth = 20;
				s.itemStyle = {
					barBorderRadius: [10, 10, 0, 0],
					color: new graphic.LinearGradient(0, 0, 0, 1, [
						{ offset: 0, color: '#f3bcbc' },
						{ offset: 1, color: '#f56c6c' },
					]),
				};
				return s;
			});
		},
	},
};
</script>
